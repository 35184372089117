import React, { useState, useEffect } from 'react';
import useScrollToSection from '../../useScrollToSection';

import { Header } from '../../components/Organisms/Header/Header';
import { HeaderMob } from '../../components/Organisms/HeaderMob/HeaderMob';
import { AboutWeb } from '../../components/Organisms/AboutWeb/AboutWeb';
import { TitleDesc } from '../../components/Organisms/TitleDesc/TitleDesc';
import { InfoCard } from '../../components/Organisms/InfoCard/InfoCard';
import { DarkText } from '../../components/Organisms/DarkText/DarkText';
import { ServiceDesc } from '../../components/Organisms/ServiceDesc/ServiceDesc';
import { Title } from '../../components/Molecules/Title/Title';
import { Footer } from '../../components/Organisms/Footer/Footer';
import { DarkTitleDesk } from '../../components/Organisms/DarkTitleDesc/DarkTitleDesc';
import PricingTable from '../../components/Organisms/PricingTable/PricingTable';

import { DropText } from '../../components/Molecules/DropText/DropText';

import { servImg, ServArr, infoData } from '../../static/serviceExp';
export function ServPage() {
  useScrollToSection();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {windowWidth <= 1100 ? <HeaderMob /> : <Header />}
      {/* <AboutWeb /> */}
      {/* <TitleDesc
        title="We develop cutting-edge solutions that propel your business forward"
        description="At HIPL, we pride ourselves on delivering top-tier development solutions that combine technical prowess with innovative thinking. Our skilled team is committed to building robust, scalable, and high-performing web applications that align with your business objectives. Whether you need a dynamic landing page, a comprehensive multi-page website, a tailored portfolio, or a custom web application, we bring a meticulous approach to every project. Our development services are designed to enhance functionality, ensure security, and provide a seamless user experience. Trust HIPL to turn your digital vision into reality with development excellence that drives your business forward."
      />
      <DarkTitleDesk
        title="We create beautiful websites that drive business growth."
        description="HIPL is your trusted partner in web design and development. At HIPL, we pride ourselves on our commitment to excellence and innovation. Our primary goal is to deliver cutting-edge web design solutions that align perfectly with your business goals. Understanding the unique essence of your brand, we focus on creating customized websites that are crafted with precision and care to meet your specific needs."
        link="#"
      /> */}
      {/* <ServiceDesc
        tabTitles={['Sweet', 'Brazilian', 'Threading']}
        tabDescriptions={[
          [
            'Sweet Waxing',
            'At KazaHeathnBeauty, we offer sweet waxing as a gentle and effective hair removal solution. Our mission is to empower individuals to achieve smooth and radiant skin while providing a comfortable and welcoming environment. Our skilled professionals use high-quality, natural ingredients to ensure a soothing experience that minimizes discomfort. We are dedicated to helping you feel confident and beautiful, one wax at a time.',
          ],
          [
            'Brazilian Waxing',
            'We specialize in Brazilian waxing, designed for those seeking a thorough and smooth hair removal experience. Our mission is to empower clients to embrace their beauty with confidence and ease. We prioritize a personalized approach, ensuring that every session is tailored to your needs in a professional and hygienic environment. Our experienced team is committed to delivering exceptional results, helping you feel fresh and fabulous.',
          ],
          [
            'Threading',
            'We offer threading services for those seeking precise and long-lasting hair removal. Our mission is to empower individuals to enhance their natural beauty through skilled techniques and personalized care. Threading is a gentle method that shapes brows and removes unwanted facial hair with accuracy, ensuring a polished look. Our dedicated professionals are committed to providing a comfortable experience, helping you achieve your desired aesthetic effortlessly.',
          ],
        ]}
      /> */}
      <DropText infoArr={infoData} />
      <PricingTable
        title="Massages"
        picture={servImg[2]}
        services={ServArr[2]}
      />
      <PricingTable title="Sweet" picture={servImg[0]} services={ServArr[0]} />
      <PricingTable
        title="Brazilian"
        picture={servImg[1]}
        services={ServArr[1]}
      />
      <PricingTable
        title="Threading"
        picture={servImg[3]}
        services={ServArr[3]}
      />
      <PricingTable
        title="Hot waxing"
        picture={servImg[4]}
        services={ServArr[4]}
      />
      <PricingTable title="Skin" picture={servImg[7]} services={ServArr[7]} />
      <PricingTable title="Nails" picture={servImg[6]} services={ServArr[6]} />
      <PricingTable title="Lashes" picture={servImg[5]} services={ServArr[5]} />
      <Title title="Ready to work with us ?" link="#" />
      <Footer />
    </>
  );
}
