import React, { useState, useRef, useEffect } from 'react';
import styles from './DropText.module.scss';

interface InfoItem {
  title: string;
  description: string;
}

interface DropTextProps {
  infoArr: InfoItem[];
}

export const DropText: React.FC<DropTextProps> = ({ infoArr }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const contentRefs = useRef<(HTMLDivElement | null)[]>([]);

  const toggleCollapse = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Our Services</h2>
      {infoArr.map((item, index) => (
        <div
          key={index}
          className={styles.item}
          onClick={() => toggleCollapse(index)}
        >
          <div className={styles.header}>
            <span className={styles.rowTitle}>{item.title}</span>
            <span className={styles.arrow}>
              {openIndex === index ? '▲' : '▼'}
            </span>
          </div>
          <div
            ref={(el) => (contentRefs.current[index] = el)}
            className={`${styles.description} ${
              openIndex === index ? styles.open : styles.closed
            }`}
            style={{
              maxHeight:
                openIndex === index
                  ? contentRefs.current[index]?.scrollHeight
                  : 0,
            }}
          >
            <p>{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
