import React, { useState, useEffect } from 'react';

import { Header } from '../../components/Organisms/Header/Header';
import { HalfText } from '../../components/Organisms/HalfText/HalfText';
import { HeaderMob } from '../../components/Organisms/HeaderMob/HeaderMob';
import { Title } from '../../components/Molecules/Title/Title';
import { Slider } from '../../components/Organisms/Slider/Slider';
import { InfoCard } from '../../components/Organisms/InfoCard/InfoCard';
import { PicDesc } from '../../components/Organisms/PicDesc/PicDesc';
import { DarkTitleDesk } from '../../components/Organisms/DarkTitleDesc/DarkTitleDesc';
import { ServiceDesc } from '../../components/Organisms/ServiceDesc/ServiceDesc';
import { DarkText } from '../../components/Organisms/DarkText/DarkText';
import { TitleDesc } from '../../components/Organisms/TitleDesc/TitleDesc';
import { Footer } from '../../components/Organisms/Footer/Footer';

export function AboutPage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {windowWidth <= 1100 ? <HeaderMob /> : <Header />}
      <HalfText
        title="Improve your health, beauty, life with us"
        description="Our services are designed to pamper and rejuvenate our clients from head to toe. Our massages are
tailored to provide relaxation and relief, offering a range of techniques from Thai to deep tissue and
more. The facials at our spa focus on skincare and rejuvenation, using high-quality products to enhance
the natural beauty of clients."
      />
      <DarkTitleDesk
        title="Smooth Skin, Expert Care"
        description="Additionally, we provide hair removal services such as Threading, Waxing,
Sweet, etc. We also provide nail and lashes services. These services collectively create an oasis of
relaxation and beauty, leaving clients feeling radiant and revitalized."
        link="/services"
      />
      {/* <PicDesc
        title="Who are we ?"
        description="We are a beauty and health company based in Toronto, Ontario. Our mission is to empower individuals to look and feel their best by offering exceptional services and products. We are committed to providing a personalized and welcoming experience for all of our clients."
        position="normal"
        pic="https://images.unsplash.com/photo-1581182815808-b6eb627a8798?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      />
      <InfoCard
        color="black"
        title="Custom websites made just for you"
        description="At HIPL, we understand that a professionally designed and developed website is crucial for your business's success. Our expert team is committed to creating websites that not only look stunning but also perform seamlessly. We meticulously plan and execute each project to ensure your website is fully responsive, user-friendly, and optimized for search engines. "
      />
      <PicDesc
        title="Who are we ?"
        description="We are a beauty and health company based in Toronto, Ontario. Our mission is to empower individuals to look and feel their best by offering exceptional services and products. We are committed to providing a personalized and welcoming experience for all of our clients."
        position="reversed"
        pic="https://images.unsplash.com/photo-1524502397800-2eeaad7c3fe5?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" // Sample image URL
      /> */}
      {/* <ServiceDesc
        tabTitles={['Experience']}
        tabDescriptions={[
          [
            'Improve your health, beauty, life with us',
            'We are a beauty and health company based in Edmonton, Alberta. Our mission is to empower individuals to look and feel their best by offering exceptional services and products. We are committed to providing a personalized and welcoming experience for all of our clients. Our team of skilled professionals is dedicated to helping you achieve your desired results.',
          ],
        ]}
      /> */}

      <Title title="Ready to work with us ?" link="/appointments" />
      <Footer />
    </>
  );
}
